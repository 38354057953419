import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardText, CardTitle, Col, Container, Row, } from 'reactstrap'
import userService from '../../../Services/UserService';

export default function SubscriptionCard({subscription,toggleParentModal}) {
  const {
    plan,
    paymentMethod,
    firstBillingDay,
    lastBillingDay,
    nextBillingDate,
    status,
    user
  } = subscription;
  const [selectedDate, setSelectedDate] = useState(new Date(lastBillingDay)); // State for selected date
  const [dateDifference, setDateDifference] = useState(0); // State for difference in days
  const [toSave,setToSave] = useState(false);
  const renewalOptions = [
    { value: 1, label: '1 lună' },
    { value: 6, label: '6 luni' },
    { value: 12, label: '1 an' },
  ];
  const handleDateChange = (date) => {
    const newDate = date;
    setSelectedDate(newDate);
    calculateDateDifference(newDate, lastBillingDay); // Update date difference
  };
  useEffect(()=>{
    if(dateDifference != 0 ){
      setToSave(true);
    }else{
      setToSave(false);
    }
  },[dateDifference])

  const calculateDateDifference = (newDate, originalDate) => {
    const adjustedNewDate = moment(newDate).startOf('day');
    const diffInDays = moment.duration(adjustedNewDate.diff(originalDate)).asDays();
    setDateDifference(Math.round(diffInDays) + 1); // Ensure positive difference
  };
  const handleRenewalChange = (selectedOption) => {
    const newDate = moment(lastBillingDay).add(selectedOption.value, 'months').toDate();
    calculateDateDifference(newDate, lastBillingDay); // Use existing function
    setSelectedDate(newDate); // Update selected date for display
    setToSave(true); // Enable save button
  };
  const handleSaveSubscription = async ()=>{
    try {
      await userService.renewSubscription(user.id,dateDifference);
      toast.success('Abonament salvat cu succes!', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error('Eroare la salvare abonament!', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  const cancelSubscription = ()=>{
    try {
        const response = userService.cancelSubscription(user.id);
        toast.success('Abonament anulat cu succes!', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    } catch (error) {
        toast.error('Anularae abonamentului a esuat!', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    }
}
  return (
    <Container>
      <Card>
        <CardBody style={{ width: '100%' }}>
          <CardTitle tag="h5">{user.name}</CardTitle>
          <Row className="d-none d-md-flex">
            <Row>
              <Col><CardText><strong>Email:</strong> {user.email}</CardText></Col>
              <Col><CardText><strong>Telefon:</strong> <Link to={`tel:${user.telephoneNumber}`}>{user.telephoneNumber}</Link></CardText></Col>
              <Col><CardText><strong>IDNO:</strong> {user.idno}</CardText></Col>
            </Row>
            <Row>
              <Col><CardText><strong>Status abonament:</strong> {status}</CardText></Col>
              <Col><CardText><strong>Metoda de plată:</strong> {paymentMethod}</CardText></Col>
              <Col><CardText><strong>Tip abonament:</strong> {plan.id == "lun20" ? "Lunar" : plan.id == "sem80" ? "Semestrial" : plan.id == "anu150" && "Anual"}</CardText></Col>
            </Row>
            <Row>
              <Col><CardText><strong>Data primei facturări:</strong> {moment(firstBillingDay).format('DD.MM.YYYY')}</CardText></Col>
             

              <Col>
              <CardText>
                <strong>Prelungiți abonamentul:</strong>
                {/* Replace ReactDatePicker with Select */}
                <ReactSelect
                  options={renewalOptions}
                  onChange={handleRenewalChange}
                  placeholder="Selectați durata" // Placeholder in Romanian (adapt for your language)
                  defaultValue={renewalOptions[0]} // Pre-select first option (optional)
                />
              </CardText>
              <CardText><strong>Data ultimei facturări:</strong><ReactDatePicker 
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat='dd.MM.yyyy'
                minDate={new Date(lastBillingDay)}
              /></CardText></Col>
              <Col><CardText><strong>Data următoarei facturări:</strong> {moment(nextBillingDate).format('DD.MM.YYYY')}</CardText></Col>
            </Row>
          </Row>
        </CardBody>
        <CardFooter>
          <Row className="d-none d-md-flex">
          {toSave && <Col><Button color="primary" onClick={handleSaveSubscription}>Salvează</Button></Col>}
          <Col><Button color="secondary" onClick={cancelSubscription}>Anulează abonament</Button></Col>
          <Col></Col>
          </Row>
          <Row className="d-md-none">
          {toSave && <Col><Button color="primary" onClick={handleSaveSubscription}>Salvează</Button></Col>}
          <Col><Button color="secondary" onClick={cancelSubscription}>Anulează abonament</Button></Col>
          <Col></Col>
          </Row>
        </CardFooter>
      </Card>
    </Container>
  )
}
