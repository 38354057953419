import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import {Col, Container, Row } from 'reactstrap';
import UsersTable from '../../General/Users/UsersTable';
const SamplePage = () => {


  return (
    <Fragment>
      <Breadcrumbs mainTitle='Utilizatori' parent='Pages' title='Utilizatori' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
           <UsersTable/>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SamplePage;
