import { Fragment } from '@fullcalendar/react'
import React from 'react'
import Chart from 'react-apexcharts';
import { TrendingUp } from 'react-feather';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, H5, P } from '../../../AbstractElements'
import ConfigDB from '../../../Config/ThemeConfig';
import { useAppData } from '../../../context/AppDataContext';
import SubscriptionsTable from '../../General/Subscriptions/SubscriptionsTable';

export default function SubscriptionsPage() {
  const{subscriptions} = useAppData();
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;
const countSubscriptions = (subscriptions) => {
  return subscriptions.reduce((acc, subscription) => {
    if (subscription.status === "active") {
      const type = subscription.plan.billingCycle;
      if (acc[type]) {
        acc[type]++;
      } else {
        acc[type] = 1;
      }
    }
    return acc;
  }, {});
};

const subscriptionCounts = countSubscriptions(subscriptions);


const radialChartLive = {
  series: [
    subscriptionCounts['monthly'] || 0,
    subscriptionCounts['semi-annual'] || 0,
    subscriptionCounts['annual'] || 0,
  ],
  options: {
    chart: {
      type: 'radialBar',
      height: 375,
      offsetY: -30,
      offsetX: 20,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '30%',
        },
        track: {
          background: '#f2f2f2',
          strokeWidth: '10%',
          margin: 3,
        },
        dataLabels: {
          name: {
            fontSize: '22px',
          },
          value: {
            fontSize: '16px',
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex];
            }
          },
        },
      },
    },
    labels: ['Lunar', 'Semestrial', 'Anual'],
    legend: {
      show: true,
      fontSize: '16px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      labels: {
        colors: '#2C323F',
      },
      markers: {
        width: 86,
        height: 18,
        radius: 3,
      },
    },
    colors: [secondary, primary, '#51bb25'],
    responsive: [
      {
        breakpoint: 767,
        options: {
          title: {
            style: {
              fontSize: '16px',
            },
          },
          legend: {
            show: true,
            fontSize: '16px',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
            labels: {
              colors: '#2C323F',
            },
            markers: {
              width: 50,
              height: 10,
              radius: 2,
            },
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            offsetX: 0,
          },
          legend: {
            show: true,
            fontSize: '16px',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
            position: 'bottom',
            labels: {
              colors: '#2C323F',
            },
            markers: {
              width: 20,
              height: 8,
              radius: 2,
            },
          },
        },
      },
    ],
  },
};
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Abonamente' parent='Pages' title='Abonamente' />
      <Row>
      {/* <Col xl='6' lg='12' className='box-col-6 xl-50 box-col-6 xl-50'> */}
      <Col>
        <Card>
          <CardHeader>
            <Row>
              <Col xs='9'>
                <H5>Tipuri abonamente</H5>
              <P>Abonamente lunare : { subscriptionCounts['monthly'] || 0} : {( subscriptionCounts['monthly'] * 300  || 0)} MDL</P>
              <P>Abonamente semestriale : { subscriptionCounts['semi-annual'] || 0} :  { (subscriptionCounts['semi-annual'] * 1350 || 0) } MDL</P>
              <P>Abonamente anuale : {subscriptionCounts['annual'] || 0} :  { ((subscriptionCounts['annual'] - 3) * 2520) || 0 } MDL</P>
              </Col>
              <Col xs='3' className='text-end'>
                <TrendingUp className='text-muted' />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className='chart-container'>
              <div id='circlechart'>
              <Chart options={radialChartLive.options} series={radialChartLive.series} type="pie" height="350" />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      </Row>
      <Container fluid={true}>
      <Row>
        <Col sm='12'>
          <SubscriptionsTable/>
        </Col>
      </Row>
      </Container>
    </Fragment>
  )
}
