import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, P } from '../../../AbstractElements';
import { EmailAddress, ForgotPassword, LoginWithJWT, Password, RememberPassword, SignIn } from '../../../Constant';

import { useNavigate } from 'react-router-dom';
import { Jwt_token } from '../../../Config/Config';
import man from '../../../assets/images/dashboard/profile.png';
import { handleResponse } from '../../../Services/fack.backend';
import useLogin from '../../../Hooks/useLogin';
import CustomizerContext from '../../../_helper/Customizer';
import OtherWay from './OtherWay';
import { useAuth } from '../../../context/AuthContext';

const LoginTab = ({ selected }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const {login} = useLogin();


  return (
    <Fragment>
      <Form className='theme-form'>
        <H4>{selected === 'simpleLogin' ? 'Sign In With Simple Login' : 'Sign In With Jwt'}</H4>
        <P>{'Enter your email & password to login'}</P>
        <FormGroup>
          <Label className='col-form-label'>{EmailAddress}</Label>
          <Input className='form-control' type='email' onChange={(e) => setEmail(e.target.value)} value={email} />
        </FormGroup>
        <FormGroup className='position-relative'>
          <Label className='col-form-label'>{Password}</Label>
          <div className='position-relative'>
            <Input className='form-control' type={togglePassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} value={password} />
            <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
              <span className={togglePassword ? '' : 'show'}></span>
            </div>
          </div>
        </FormGroup>
        <div className='position-relative form-group mb-0'>
          <div className='checkbox'>
            <Input id='checkbox1' type='checkbox' />
            <Label className='text-muted' for='checkbox1'>
              {RememberPassword}
            </Label>
          </div>
          <a className='link' href='#javascript'>
            {ForgotPassword}
          </a>
          {selected === 'simpleLogin' ? (
            <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => login(email,password) }}>{SignIn}</Btn>
          ) : (
            <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => login(email,password) }}>{LoginWithJWT}</Btn>
          )}
        </div>
        <OtherWay />
      </Form>
    </Fragment>
  );
};

export default LoginTab;
