import { Fragment } from '@fullcalendar/react'
import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements'
import TransactionsTable from '../../General/Transactions/TransactionsTable'

export default function TransactionsPage() {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Tranzactii' parent='Pages' title='Tranzactii' />
      <Container fluid={true}>
      <Row>
        <Col sm='12'>
          <TransactionsTable/>
          </Col>
      </Row>
      </Container>
      
    </Fragment>
  )
}
