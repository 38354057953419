import moment from "moment";

const transactionColumns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
      center: false,
      width:"10%"
    },
    {
      name: 'Nume',
      selector: row => row.name,
      sortable: true,
      center: false,
      width:"20%",
    },
    {
      name: 'Sumă',
      selector: row => row.amount,
      sortable: true,
      center: false,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      center: false,
      conditionalCellStyles: [
        {
            when: row => row.status == "successful",
            style: {
                color: 'rgba(63, 195, 128, 0.9)',
                '&:hover': {
                    cursor: 'default',
                },
            },
        },
        {
            when: row => row.status == "pending",
            style: {
                color: 'rgba(248, 148, 6, 0.9)',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status == "failed",
            style: {
                color: 'rgba(242, 38, 19, 0.9)',
                '&:hover': {
                    cursor: 'not-allowed',
                },
            },
        },
    ],
    },
    {
      name: 'Data',
      selector: row => moment(row.timestamp).format('DD.MM.YYYY HH:mm'),
      sortable: true,
      center: false,
    },
    {
      name: 'Număr de Card',
      selector: row => row.cardNumber ? row.cardNumber : row.amount >= 300 ? 'Transfer' : "N/A",
      sortable: true,
      center: false,
    },
  ];
  
  export default transactionColumns;
  