import instance from "../interceptor/AxiosInterceptor";

const BASE_URL = "/api/auth";
const OAUTH2_URL = "/api/oauth2";


const authService = {
    login: async (email, password) => {
        try {
            const response = await instance.post(`${BASE_URL}/login`, {
                username: email,
                password: password
            }, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': '*/*'
                },
                withCredentials: true
            });
            
            return response.data;
        } catch (error) {
            
            throw error;
        }
    },

    
    logout: async() => {
        try {
            const response = await instance.post(`${BASE_URL}/logout`, {}, { 
                withCredentials: true 
            });
            return response.data;
        } catch(error) {
            throw error;
        }
    },

    

};
export default authService;