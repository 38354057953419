import React, { useMemo, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { P } from '../../../AbstractElements';
import subscriptionColumns from '../../../assets/data/subscriptionsColumns';
import { useAppData } from '../../../context/AppDataContext';
import ReactTable from 'react-data-table-component';
import SubscriptionCard from './SubscriptionCard';
import FilterComponent from '../../Common/Component/FilterComponent';

export default function SubscriptionsTable() {
    const{subscriptions} = useAppData();
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const activatedSubscriptionsCount = () => {
        if (!subscriptions) return 0;

        const enabledSubscriptions = subscriptions.filter(subscription => subscription.status === "active");
        return enabledSubscriptions.length;
    };

    const handleRowClick = subscription => {
        setSelectedSubscription(subscription);
        toggle();
    };
    const conditionalRowStyles = [
      {
        when: row => true,
        style: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    ];
    const modalContent = selectedSubscription ? (
        <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>Detalii abonament</ModalHeader>
            <ModalBody>
                <SubscriptionCard subscription={selectedSubscription} />
            </ModalBody>
        </Modal>
    ) : null;

    const filteredSubscriptions = useMemo(() => {
        if (!subscriptions) return [];
        return subscriptions.filter(subscription => subscription.user && subscription.user.name.toLowerCase().includes(filterText.toLowerCase()));
    }, [subscriptions, filterText]);

    const handleClearFilter = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
    };

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <FilterComponent 
                onFilter={e => setFilterText(e.target.value)} 
                onClear={handleClearFilter} 
                filterText={filterText} 
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
      <>
          <Card>
              <CardHeader>
                  <P>Abonamente active: {activatedSubscriptionsCount()}</P>
              </CardHeader>
              <CardBody>
                  <ReactTable
                      columns={subscriptionColumns}
                      data={filteredSubscriptions}
                      noHeader
                      pagination
                      highlightOnHover
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      onRowClicked={handleRowClick} // Added row click handler
                      conditionalRowStyles={conditionalRowStyles}
                  />
              </CardBody>
          </Card>
          {modalContent}
      </>
  );
}
