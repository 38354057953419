// //Samp lePage
import GeneralPage from '../Components/Pages/PageLayout/GeneralPage';
import SamplePage from '../Components/Pages/PageLayout/SimplePage';
import SubscriptionsPage from '../Components/Pages/PageLayout/SubscriptionsPage';
import TransactionsPage from '../Components/Pages/PageLayout/TransactionsPage';

export const routes = [
  // //page
  { path: `${process.env.PUBLIC_URL}/pages/users/:layout`, Component: <SamplePage /> },
  { path: `${process.env.PUBLIC_URL}/pages/subscriptions/:layout`, Component: <SubscriptionsPage /> },
  { path: `${process.env.PUBLIC_URL}/pages/general/:layout`, Component: <GeneralPage /> },
  { path: `${process.env.PUBLIC_URL}/pages/transactions/:layout`, Component: <TransactionsPage /> },
];
