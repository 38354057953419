import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { P } from '../../../AbstractElements';
import transactionColumns from '../../../assets/data/transactionColumns';
import { useAppData } from '../../../context/AppDataContext';
import userService from '../../../Services/UserService';
import SubscriptionActivationModal from '../../Common/Modal/SubscriptionActivationModal';
import ReactTable from 'react-data-table-component';
export default function TransactionsTable({pending}) {
    const [transactionId,setTransactionId] = useState('');
    const{transactions, users,refetchData} = useAppData();
    let filteredTransactions = pending
    ? transactions.filter(transaction => transaction.status === "pending")
    : transactions;

    const successTransactionsCount = () => {
        if (!transactions) return 0; // Return 0 if data is null
    
        // Filter the users based on the 'enabled' field
        const enabledTransactions = transactions.filter(transaction => transaction.status == "successful");
        return enabledTransactions.length; // Return the count of enabled users
    };
    const pendingTransactionsCount = () => {
        if (!transactions) return 0; // Return 0 if data is null
    
        // Filter the users based on the 'enabled' field
        const enabledTransactions = transactions.filter(transaction => transaction.status == "pending");
        return enabledTransactions.length; // Return the count of enabled users
    };
    
    const conditionalRowStyles = [
      {
        when: row => row.status == "pending",
        style: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
        
      },
    
    ];
    const handleRowClick = (row) => {
      if(row.status == "pending"){
        setTransactionId(row.id);
      toggle();
      }
    }
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const activateSubscription = (e) =>{
      e.preventDefault();
      try {
        userService.activateSubscription(transactionId);
        toast.success('Abonament activat cu succes!', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        filteredTransactions = filteredTransactions.filter(transaction => transaction.id !== transactionId);
        toggle();
        refetchData();
      } catch (error) {
        toast.error('Eroare la activare abonament', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

  return (
    <>

            <Card>
            <CardHeader>
                {pending ? <P>Tranzactii in astepare: {pendingTransactionsCount()}</P>
                : <P>Tranzactii finalizate: {successTransactionsCount()}</P>}
                </CardHeader>
                <CardBody>
                <ReactTable
                    columns={transactionColumns}
                    data={filteredTransactions}
                    noHeader
                    pagination
                    highlightOnHover
                    conditionalRowStyles={conditionalRowStyles}
                    onRowClicked={handleRowClick}
                />
                </CardBody>
            </Card>
        <SubscriptionActivationModal
        modal = {modal}
        handleSubmit = {activateSubscription}
        toggle = {toggle}
        transactionId = {transactionId}
        />
    </>
    
  )
}
