import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardText, CardTitle, Col, Container, Row, } from 'reactstrap';
import userService from '../../../Services/UserService';
import GenerateTokensModal from './GenerateTokensModal';

export default function UserCard({user,toggleParentModal}) {
    const [generateTokensModal, setGenerateTokensModal] = useState(false);
    const toggleGenerateTokensModal = () => setGenerateTokensModal(!generateTokensModal);
    const activateSuperAccount = async (e) => {
      e.preventDefault();
      try {
          const response = await userService.setupSuperAccount(user.id);
          toast.success('Supercont activat cu succes!', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
      } catch (error) {
          toast.error('Eroare la activare supercont!', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
      }
  }
  const cancelSuperAccount = async (e) => {
    e.preventDefault();
    try {
        const response = await userService.cancelSuperAccount(user.id);
        toast.success('Supercont dezactivat cu succes!', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    } catch (error) {
        toast.error('Eroare la dezactivare supercont!', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    }
}
    const cancelSubscription = ()=>{
        try {
            const response = userService.cancelSubscription(user.id);
            toast.success('Abonament anulat cu succes!', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        } catch (error) {
            toast.error('Anularae abonamentului a esuat!', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }

  return (
    <Container>
     <Card>
        <CardBody style={{ width: '100%' }}>
            <CardTitle tag="h5">{user.name}</CardTitle>
              <Row className="d-none d-md-flex">
              <Row>
                <Col><CardText><strong>Email:</strong> {user.email}</CardText></Col>
                <Col><CardText><strong>Rol:</strong> {user.role}</CardText></Col>
                <Col><CardText><strong>Tokenuri:</strong> {user.balance}</CardText></Col>
               </Row>
               <Row>
                <Col><CardText ><strong>Status abonament:</strong> {user.subscriptionStatus ? "Activ: " + (user.subscription.plan.id == "lun20" ? "Lunar" :user.subscription.plan.id == "sem80" ? "Semestrial" : "Anual") : user.subscription && user.subscription.status  == "pending" ? "In asteptare" : "Inactiv"}</CardText></Col>
                <Col><CardText><strong>Ultima autentificare:</strong> {new Date(user.lastLoginTime).toLocaleString()}</CardText></Col>
                <Col><CardText><strong>Data creării:</strong> {new Date(user.creationDate).toLocaleString()}</CardText></Col>
               </Row>
               <Row>
                <Col><CardText><strong>Telefon:</strong> <Link to={`tel:${user.telephoneNumber}`}>{user.telephoneNumber}</Link></CardText></Col>
                <Col><CardText><strong>Termeni acceptați:</strong> {user.termsAccepted ? "Da" : "Nu"}</CardText></Col>
                <Col><CardText><strong>Sesiuni active curente:</strong> {user.currentActiveSessions}</CardText></Col>
               </Row>
               <Row>
                <Col><CardText><strong>IDNO:</strong> {user.idno}</CardText></Col>
                <Col><CardText><strong>Număr card salvat:</strong> {user.savedCardNumber}</CardText></Col>
                <Col><CardText><strong>Tip persoană:</strong> {user.personType ? 'Juridică' : 'Fizică'}</CardText></Col>
               </Row>
               <Row>
                <Col><CardText><strong>Număr de calcule:</strong> {user.numberOfCalcs}</CardText></Col>
                <Col><CardText><strong>Calcule achitate:</strong> {user.numberOfPaidCalcs}</CardText></Col>
                <Col><CardText><strong>Calcule importate:</strong> {user.numberOfImportedCalcs}</CardText></Col>
               </Row>
               <Row>
  <Col>
    <h6>Subconturi:</h6>
    {user.subUsers && user.subUsers.length > 0 ? (
      <ul style={{ maxHeight: '120px', overflowY: 'auto', listStyleType: 'none', paddingLeft: '0' }}>
        {user.subUsers.map((subUser, index) => (
          <li key={subUser.id} style={{ padding: '8px 0', borderBottom: '1px solid #ddd' }}>
            <strong>ID:</strong> {subUser.id}, <strong>Email:</strong> {subUser.email}
          </li>
        ))}
      </ul>
    ) : (
      <p>Nu există subconturi disponibile.</p>
    )}
  </Col>
</Row>


              </Row>
              <Row className="d-md-none">
            
                <CardText><strong>Email:</strong> {user.email}</CardText>
                <CardText><strong>Rol:</strong> {user.role}</CardText>
                <CardText><strong>Tokenuri:</strong> {user.balance}</CardText>
               
            
                <CardText><strong>Status abonament:</strong> {user.subscriptionStatus ? "Activ: " + (user.subscription.plan.id == "lun20" ? "Lunar" :user.subscription.plan.id == "sem80" ? "Semestrial" : "Anual") : user.subscription && user.subscription.status  == "pending" ? "In asteptare" : "Inactiv"}</CardText>
                <CardText><strong>Ultima autentificare:</strong> {new Date(user.lastLoginTime).toLocaleString()}</CardText>
                <CardText><strong>Data creării:</strong> {new Date(user.creationDate).toLocaleString()}</CardText>
               
               
                <CardText><strong>Telefon:</strong> <Link to={`tel:${user.telephoneNumber}`}>{user.telephoneNumber}</Link></CardText>
                <CardText><strong>Termeni acceptați:</strong> {user.termsAccepted ? "Da" : "Nu"}</CardText>
                <CardText><strong>Sesiuni active curente:</strong> {user.currentActiveSessions}</CardText>
               
               
                <CardText><strong>IDNO:</strong> {user.idno}</CardText>
                <CardText><strong>Număr card salvat:</strong> {user.savedCardNumber}</CardText>
                <CardText><strong>Tip persoană:</strong> {user.personType ? 'Juridică' : 'Fizică'}</CardText>
               
                <CardText><strong>Număr de calcule:</strong> {user.numberOfCalcs}</CardText>
                <CardText><strong>Calcule achitate:</strong> {user.numberofPaidCalcs}</CardText>
                <CardText><strong>Calcule importate:</strong> {user.numberOfImportedCalcs}</CardText>
              </Row>
              
        </CardBody>
            <CardFooter>
                <Row className="d-none d-md-flex">
                <Col><Button color="primary" onClick={toggleGenerateTokensModal}>Generează tokenuri</Button></Col>
                <Col>
                {user.isSuperaccount ? <Button color="secondary" onClick={cancelSuperAccount}>Anulează supercont</Button>
                : <Button color="primary" onClick={activateSuperAccount}>Activează supercont</Button>}

                </Col>
                <Col><Button color="secondary" onClick={cancelSubscription}>Anulează abonament</Button></Col>
                </Row>
                <Row className="d-md-none">
                <Button color="primary" onClick={toggleGenerateTokensModal} style={{ marginBottom: '10px' }}>Generează tokenuri</Button>
                <Button color="secondary" onClick={cancelSubscription}>Anulează abonament</Button>
                </Row>
            </CardFooter>
    </Card>

    <GenerateTokensModal
        modal={generateTokensModal}
        toggle={toggleGenerateTokensModal}
        userId={user.id}
    />

</Container>
  )
}
