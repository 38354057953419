import moment from "moment";

const subscriptionColumns = [
  {
    name: 'Utilizator',
    selector: row => row.user ? row.user.name : 'error',
    sortable: true,
    center: false,
  },
    {
      name: 'Ciclu de Facturare',
      selector: row => row.plan.billingCycle,
      sortable: true,
      center: false,
    },
    {
      name: 'Metodă de Plată',
      selector: row => row.paymentMethod,
      sortable: true,
      center: false,
    },
    {
      name: 'Prima Zi de Facturare',
      selector: row => moment(row.firstBillingDay).format('DD.MM.YYYY'),
      sortable: true,
      center: false,
    },
    // {
    //   name: 'Ultima Zi de Facturare',
    //   selector: row => moment(row.lastBillingDay).format('DD.MM.YYYY'),
    //   sortable: true,
    //   center: false,
    // },
    {
      name: 'Data Următoarei Facturări',
      selector: row => moment(row.nextBillingDate).format('DD.MM.YYYY'),
      sortable: true,
      center: false,
    },
    {
      name: 'Statut',
      selector: row => row.status,
      sortable: true,
      center: false,
      conditionalCellStyles: [
        {
            when: row => row.status == "active",
            style: {
                color: 'rgba(63, 195, 128, 0.9)',
                '&:hover': {
                    cursor: 'default',
                },
            },
        },
        {
            when: row => row.status == "pending",
            style: {
                color: 'rgba(248, 148, 6, 0.9)',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status == "canceled",
            style: {
                color: 'rgba(242, 38, 19, 0.9)',
                '&:hover': {
                    cursor: 'not-allowed',
                },
            },
        },
    ],
    },
  ];
  
  export default subscriptionColumns;
  