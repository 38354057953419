import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import userService from '../../../Services/UserService';
export default function GenerateTokensModal({modal,toggle,userId, ...args}) {
    const [tokens, setTokens] = useState(null);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await userService.addTokensForUser(userId,tokens);
            toast.success('Tokenuri generate cu succes!', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            toggle();
        } catch (error) {
            toast.error('Eroare la generare tokenuri', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }
  return (
    <div>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                        <Label>Nr tokenuri:</Label>
                        <Input type='numeric' value={tokens} onChange={(e)=>setTokens(e.target.value) } />
                </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmit}>
            Genereaza
            </Button>{' '}
            <Button color="secondary" onClick={toggle} type="button">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  )
}
