import { Fragment } from '@fullcalendar/react'
import React from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { Breadcrumbs, H5 } from '../../../AbstractElements'
import WidgetsWrapper from '../../General/General/WidgetsWraper'
import SalesChart from '../../General/Transactions/SalesChart'
import TransactionsTable from '../../General/Transactions/TransactionsTable'

export default function GeneralPage() {
  return (
    <Fragment>
    <Breadcrumbs mainTitle='General' parent='Pages' title='General' />
    <Container fluid={true}>
    <Row className="widget-grid">
        <WidgetsWrapper/>
        <SalesChart/>
    </Row>
    <Row>
    <TransactionsTable
        pending
        />
    </Row>
    </Container>
  </Fragment>
  )
}
