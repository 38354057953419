import React, { Fragment, useEffect, useState } from 'react';
import Charts from 'react-apexcharts';
import { Navigation } from 'react-feather';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import ConfigDB from '../../../Config/ThemeConfig';
import { useAppData } from '../../../context/AppDataContext';

const SalesChart = () => {
    const{transactions} = useAppData();
    const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;
    const [salesData, setSalesData] = useState({
        options: {},
        series: [],
    });
    const columnChart = {
        options: {
          chart: {
            height: 350,
            type: 'line',
      
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
      
          title: {
            text: 'Suma',
            align: 'left',
            style: {
              fontSize: '12px',
            },
          },
          subtitle: {
            text: 'MDL',
            floating: true,
            align: 'right',
            offsetY: 0,
            style: {
              fontSize: '20px',
              fontWeight: 500,
            },
          },
          fill: {
            colors: [primary],
            type: 'gradient',
            gradient: {
              shade: 'light',
              type: 'vertical',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [0, 100],
            },
          },
          xaxis: {
            categories: ['Azi', 'Saptamanal', 'Lunar', 'Total'],
          },
          yaxis: {
            decimalsInFloat: 1,
          },
          legend: {
            show: true,
          },
          responsive: [
            {
              breakpoint: 1366,
              options: {
                subtitle: {
                  style: {
                    fontSize: '18px',
                  },
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                subtitle: {
                  style: {
                    fontSize: '16px',
                  },
                },
              },
            },
          ],
        },
      };
    useEffect(() => {
        // Calculate total sales for different time intervals
        const todaySales = calculateSalesForInterval(transactions, 'today');
        const weeklySales = calculateSalesForInterval(transactions, 'week');
        const monthlySales = calculateSalesForInterval(transactions, 'month');
        const totalSales = transactions
        .filter(transaction => transaction.status === 'successful')
        .reduce((total, transaction) => total + transaction.amount, 0);
    

        // Set the sales data for ApexCharts
        setSalesData({
            options: columnChart.options,
            series: [{
                name: 'Incasari',
                data: [todaySales, weeklySales, monthlySales, totalSales],
            }],
        });
    }, [transactions]);

    const calculateSalesForInterval = (transactions, interval) => {
        let intervalSales = 0;
        const today = new Date();
    
        switch (interval) {
            case 'today':
                intervalSales = transactions
                    .filter(transaction => isSameDay(new Date(transaction.timestamp), today) && transaction.status === 'successful')
                    .reduce((total, transaction) => total + transaction.amount, 0);
                break;
            case 'week':
                const startOfWeek = getStartOfWeek(today);
                intervalSales = transactions
                    .filter(transaction => new Date(transaction.timestamp) >= startOfWeek && transaction.status === 'successful')
                    .reduce((total, transaction) => total + transaction.amount, 0);
                break;
            case 'month':
                const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                intervalSales = transactions
                    .filter(transaction => new Date(transaction.timestamp) >= startOfMonth && transaction.status === 'successful')
                    .reduce((total, transaction) => total + transaction.amount, 0);
                break;
            default:
                break;
        }
    
        return intervalSales;
    };
    

    const isSameDay = (date1, date2) => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    };

    const getStartOfWeek = (date) => {
        const dayOfWeek = date.getDay();
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - dayOfWeek);
        return startOfWeek;
    };

    return (
        <Fragment>
            <Col>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs="9">
                                <H5>Incasari</H5>
                            </Col>
                            <Col xs="3" className="text-end"><Navigation className="text-muted" /></Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="chart-container">
                            <div id="columnchart">
                                <Charts options={salesData.options} series={salesData.series} height="380" type="bar" />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};

export default SalesChart;
