import instance from "../interceptor/AxiosInterceptor";

const BASE_URL = "/api/user";
const ADMIN_URL = "/api/admin";
const userService = {

    getCurrentUser: async () => {
        try {
            const response = await instance.get(`${BASE_URL}/details`, {
                withCredentials: true
            });
            return response.data;  
        } catch (error) {
            console.error("Failed to retrieve current user details: " + error.response);
            throw error;
        }
    },
    getAllUsers: async () =>{
        try {
            const response = await instance.get(`${ADMIN_URL}/users`,{
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error("Failed to retrieve all user details: " + error.response);
            throw error;
        }
    },
    activateSubscription: async (transactionId) =>{
        try {
            const response = await instance.post('/api/subscription/admin/activate-subscription',{transactionId},{
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error("Failed to activate subscription: " + error.response);
            throw error;
        }
    },
    getAllTransactions: async () =>{
        try {
            const response = await instance.get(`${ADMIN_URL}/transactions`,{
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error("Failed to retrieve all transactions : " + error.response);
            throw error;
        }
    },
    getAllSubscriptions: async () =>{
        try {
            const response = await instance.get(`${ADMIN_URL}/subscriptions`,{
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error("Failed to retrieve all subscriptions : " + error.response);
            throw error;
        }
    },
    addTokensForUser: async (userId, tokenCount) => {
        try {
            const response = await instance.post(`${ADMIN_URL}/add-tokens`, null, {
                params: {
                    userId: userId,
                    tokenCount: tokenCount
                },
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error("Failed to add tokens for user: " + error.response);
            throw error;
        }
    },
    cancelSubscription: async (userId) => {
        try {
            const response = await instance.post(`/api/subscription/admin/cancel-subscription`, userId, {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json' // Set Content-Type to JSON (optional)
                }
              });
            return response.data;
        } catch (error) {
            console.error("Failed to cancel subscription for user: " + error.response);
            throw error;
        }
    },  
    renewSubscription: async (userId,days) =>{
        try {
            const response = await instance.post('/api/subscription/admin/renew-subscription',{userId, days},{
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error("Failed to renew subscription: " + error.response);
            throw error;
        }
    },
    deleteTransaction: async (transactionId) => {
        try {
            const response = await instance.delete(`${ADMIN_URL}/transactions/${transactionId}`, {
                withCredentials: true,
              });
            return response.data;
        } catch (error) {
            console.error("Failed to cancel subscription for user: " + error.response);
            throw error;
        }
    },
    setupSuperAccount: async (userId) => {
        try {
            const response = await instance.post(`${ADMIN_URL}/setSuperAccount`,null,
             {
                withCredentials: true,
                params:{userId: userId,}
              });
            return response.data;
        } catch (error) {
            console.error("Failed to activate superaccount for user: " + error.response);
            throw error;
        }
    },
    cancelSuperAccount: async (userId) => {
        try {
            const response = await instance.post(`${ADMIN_URL}/cancelSuperAccount`,null,
             {
                withCredentials: true,
                params:{userId: userId,}
              });
            return response.data;
        } catch (error) {
            console.error("Failed to activate superaccount for user: " + error.response);
            throw error;
        }
    }
    
}

export default userService;